@import "variables.scss";
@import "~bootstrap/scss/bootstrap";

body {
    background-color: lightgray;
    font-family: "Poppins", sans-serif;

    header {
        ul {
            li {
                a {
                    transition: all 0.5s;
                    svg {
                        top: -3px;
                    }
                    &:not(.nav-home) {
                        &:after {
                            bottom: 0;
                            content: "";
                            display: block;
                            height: 2px;
                            left: 50%;
                            position: absolute;
                            background: #ffffff;
                            transition: width 0.3s ease 0s, left 0.3s ease 0s;
                            width: 0;
                        }
                        &:hover {
                            &:after {
                                width: 100%;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
        .container-btn-cart {
            svg {
                top: -3px;
            }
            &:hover {
                svg {
                    path {
                        stroke: $primary;
                    }
                    rect {
                        fill: $primary;
                    }
                }
            }
        }
        .notification {
            background-color: $secondary;
            width: 20px;
            height: 20px;
            top: -5px;
            padding-top: 2px;
            font-size: 11px;
        }

        .d-version {
            display: inline-block;
        }
        .m-version {
            display: none;
        }

        .container-menu-responsive {
            position: absolute;
            display: block;
            left: 100%;
            height: calc(100vh - 92px);
            top: 92px;
            transition: all .5s;
        }
        @media (max-width: 1600px) {
            .d-version {
                display: none;
            }
            .m-version {
                display: inline-block;
            }

            &.show {
                .container-menu-responsive {
                    left: 0;
                }
            }
        }
    }

    .min-custom-height {
        min-height: calc(100vh - 360px - 92px);
    }
    .container-block {
        max-width: 1160px;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .fs-15 {
        font-size: 15px;
    }
    .fs-14 {
        font-size: 14px;
    }
    .fs-13 {
        font-size: 13px;
    }
    .fs-12 {
        font-size: 12px;
    }
    .fs-11 {
        font-size: 11px;
    }
    .fs-10 {
        font-size: 10px;
    }
    .top-5 {
        top: 5px;
    }
    .bg-custom-gradient {
        background: $gradientSecondary;
    }
    .bg-cover {
        background-size: cover;
    }
    .bg-center {
        background-position: center center;
    }
    .text-gradient {
        background: -webkit-linear-gradient(45deg, #E84650, #FF953E);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    input {
        &:focus, &:active {
            outline: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }
    }
    button {
        &:disabled {
            border-color: #bbb !important;
            color: #aaa !important;;
        }
    }
    h1 {
        font-size: 48px;
        font-weight: 700;
    }

    .container-header {
        height: 360px;
        &.container-header-hp {
            @media screen and (max-width: 768px) {
                height: 540px;
            }
        }
    }
    .container-limit {
        max-width: 1160px;
        top: -65px;
        box-shadow: 0 16px 32px -4px rgba(12, 12, 13, 0.10), 0 4px 4px -4px rgba(12, 12, 13, 0.05);
        @media screen and (max-width: 768px) {
            top: 0;
        }
    }

    .banner {
        .container-time {
            &:before {
                position: absolute;
                left: 4px; top: 4px;
                content: url("/public/images/offres/Journee2.svg");
            }
        }
    }

    #block-contact {
        .container-block {
            box-shadow: 0 16px 32px -4px rgba(12, 12, 13, 0.10), 0px 4px 4px -4px rgba(12, 12, 13, 0.05);
            div.contact {
                &:before {
                    position: absolute;
                    content: url("/public/images/conduite/map.svg");
                    left: 10px;
                    top: 16px;
                }
                &.map {
                    &:before {
                        left: 6px;
                        top: 15px;
                        content: url("/public/images/conduite/gps.svg");
                    }
                }
                &.phone {
                    &:before {
                        left: 6px;
                        top: 14px;
                        content: url("/public/images/conduite/telephone.svg");
                    }
                }
                &.mail {
                    &:before {
                        left: 6px;
                        top: 14px;
                        content: url("/public/images/conduite/email.svg");
                    }
                }
            }
        }
    }
}


#cc-main {
    color-scheme: dark;

    --cc-bg: #112341;
    --cc-primary-color: rgb(239, 244, 246);
    --cc-secondary-color: #b1bdc3;

    --cc-btn-primary-bg: #FFF;
    --cc-btn-primary-color: #112341;
    --cc-btn-primary-hover-bg: #fff;
    --cc-btn-primary-border-color: #112341;
    --cc-btn-primary-hover-border-color: #112341;
    //--cc-btn-primary-hover-color: #000;

    --cc-btn-secondary-bg: #242c31;
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-hover-bg: #d4dae0;
    --cc-btn-secondary-hover-color: #000;

    --cc-cookie-category-block-bg: #1e2428;
    --cc-cookie-category-block-border: #1e2428;
    --cc-cookie-category-block-hover-bg: #242c31;
    --cc-cookie-category-block-hover-border: #242c31;
    --cc-cookie-category-expanded-block-hover-bg: #242c31;
    --cc-cookie-category-expanded-block-bg: #1e2428;
    --cc-toggle-readonly-bg: #343e45;
    --cc-overlay-bg: rgba(4, 6, 8, .85)!important;

    --cc-toggle-on-knob-bg: var(--cc-bg);
    --cc-toggle-readonly-knob-bg: var( --cc-cookie-category-block-bg);

    --cc-separator-border-color: #FFF;

    --cc-footer-border-color: #212529;
    --cc-footer-bg: #0f1112;
    .cm__desc {
        font-size: 14px !important;
    }
}
