$primary: #112341;
$secondary: #E84650;
$info: #1167CC;
$modal-backdrop-opacity: .8;

$gradientSecondary: linear-gradient(90deg, #E84650 0%, #FF953E 100%);

$container-max-widths: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1920px
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1920px
);
